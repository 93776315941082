"use strict";
import { viewports, containerMaxWidth } from "../../../config";
export const constants = Object.freeze({
  CONTAINER_MAX_WIDTH: containerMaxWidth,
  VIEWPORT_WIDTH_DESKTOP_WIDE: viewports["desktop-wide"],
  VIEWPORT_WIDTH_DESKTOP: viewports.desktop,
  VIEWPORT_WIDTH_TABLET: viewports.tablet,
  VIEWPORT_WIDTH_DESKTOP_ULTRA_WIDE: viewports["desktop-ultra-wide"]
});
export const defaultValues = Object.freeze({
  ACTIVE_CLASS: "is-active"
});
export const COMMON_ERROR_MSG = "Something went wrong. Please try again later";
export const EMAIL_INVALID_MSG = "Correct email must contain a single @ and a domain portion";
